import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import router from "@index/router";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_AUTH,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "e-finmarket.ru", /^\//],
    }),
  ],
  // @TODO разобраться, что это такое
  tracesSampleRate: 1.0,
});
